import React from 'react'
import Section1 from '../components/Section1'
import { Helmet } from 'react-helmet'

const Home = ({ settingData }) => {
  return (
    <>
      <Helmet>
        <title>{settingData?.websiteMetaTitle}</title>
        <meta name="description" content={settingData?.websiteMetaDescription} />
      </Helmet>
      <main>
        <Section1 settingData={settingData} />
      </main>
    </>
  )
}

export default Home