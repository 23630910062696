import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getMenuPage } from '../services/getMenuPage';
import { ImageLink } from '../constants';
import PreLoader from '../components/PreLoader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet';

const MadeUp = () => {
  const setting = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    loop: true,
    arrows: false,
    dots: false,
    Infinity: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };
  const location = useLocation();
  const menuUrl = location.pathname
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getMenuPage(menuUrl);
      setGetData(response.data);
      setLoading(false);
    };
    fetchData();
  }, [menuUrl]);


  if (loading || getData.length > 0) {
    return <PreLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{getData?.metaTitle}</title>
        <meta name="description" content={getData?.metaDescription} />
      </Helmet>
      <main>
        <div className="video-w">
          <div className="video-banner-content-center">
            <h1 className="text-uppercase">{getData.menuName}</h1>
            <h4 dangerouslySetInnerHTML={{ __html: getData.pageId?.content }} />
          </div>
          <div className="video-overlay" />
          {getData.pageId?.desktopImg?.endsWith('.mp4') ?
            <video className="desktop-video" width="100%" loop
              autoPlay
              muted>
              <source src={ImageLink(getData.pageId?.desktopImg)} />
            </video>
            :
            <img className="desktop-video" src={ImageLink(getData.pageId?.desktopImg)} alt='desktop_img' />
          }

          {getData.pageId?.mobileImg?.endsWith('.mp4') ?
            <video className="mobile-video" width="100%" loop
              autoPlay
              muted>
              <source src={ImageLink(getData.pageId?.mobileImg)} />
            </video> :
            <img className="mobile-video" src={ImageLink(getData.pageId?.mobileImg)} alt='mobile_img' />
          }
        </div>
        {getData.pageId?.contentData?.map((item, id) => {
          const isEven = id % 2 === 0;
          return (
            <React.Fragment key={id}>
              <section className={`fabrics-area mt-5 ${item.product.length > 0 || id === 0 ? '' : 'bottom-line'}`}>
                <div className="container">
                  <div className="row pb-20">
                    {item.desktopImg.endsWith('.mp4') ? (
                      <>
                        <div className={`col-lg-8 `}>
                          <video
                            className="img-radius mb-5"
                            width="100%"
                            loop
                            autoPlay
                            muted
                          >
                            <source src={ImageLink(item.desktopImg)} />
                          </video>
                        </div>
                        <div className={`col-lg-4`}>
                          <div>
                            <div className="section-title text-left">
                              <h2 className="title">{item.title}</h2>
                            </div>
                            <div className="text-left">
                              <p dangerouslySetInnerHTML={{ __html: item.description }} />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {id === 0 ? (
                          <>
                            <div className="col-lg-8">
                              <img className="img-radius mb-5" src={ImageLink(item.desktopImg)} alt='desktop_img' />
                            </div>
                            <div className={`col-lg-4 `}>
                              <div>
                                <div className="section-title text-left">
                                  <h2 className="title">{item.title}</h2>
                                </div>
                                <div className="text-left">
                                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : id !== 0 ? (
                          <>
                            <div className={`col-lg-6 order-${isEven ? "2" : "1"}`}>
                              <img className="img-radius mb-5" src={ImageLink(item.desktopImg)} alt='desktop_img' />
                            </div>
                            <div className={`col-lg-6 order-${isEven ? "1" : "2"}`}>
                              <div>
                                <div className="section-title mb-35">
                                  <h2 className="title">{item.title}</h2>
                                </div>
                                <div className="">
                                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </section>

              {item.product.length === 0 ? '' :
                <section className={`project-area project-style-three section-pb-80  ${item.product.length === 0 ? '' : 'bottom-line'}`}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <Slider {...setting}
                          className="madeup-active mySwiper"

                        >
                          {item.product.map((product, id) => (
                            <div className="project-item" key={id}>
                              <div>
                                <img
                                  className="img-radius mb-5"
                                  src={ImageLink(product.desktopImg)}
                                  alt="product_details"
                                  style={{ width: "285px", height: "316px", marginRight: "10px" }}
                                />
                                <div className="certifi-content">{product.title}</div>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </React.Fragment>
          );
        })}
      </main >
    </>
  )
}

export default MadeUp