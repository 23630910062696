import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getMenuPage } from '../services/getMenuPage';
import { ImageLink } from '../constants';
import PreLoader from '../components/PreLoader';
import { Helmet } from 'react-helmet';

const Fabrics = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const menuUrl = location.pathname
  const [getData, setGetData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getMenuPage(menuUrl);
      setGetData(response.data);
      setLoading(false);
    };
    fetchData();
  }, [menuUrl]);

  if (loading || getData.length > 0) {
    return <PreLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{getData?.metaTitle}</title>
        <meta name="description" content={getData?.metaDescription} />
      </Helmet>
      <main>
        <div className="video-w">
          <div className="video-banner-content-center">
            <h1 className="text-uppercase">{getData.menuName}</h1>
            <h4 dangerouslySetInnerHTML={{ __html: getData.pageId?.content }} />
          </div>
          <div className="video-overlay" />
          {getData.pageId?.desktopImg?.endsWith('.mp4') ?
            <video className="desktop-video" width="100%" loop
              autoPlay
              muted>
              <source src={ImageLink(getData.pageId?.desktopImg)} />
            </video>
            :
            <img className="desktop-video" src={ImageLink(getData.pageId?.desktopImg)} alt='desktop_img' />
          }

          {getData.pageId?.mobileImg?.endsWith('.mp4') ?
            <video className="mobile-video" width="100%" loop
              autoPlay
              muted>
              <source src={ImageLink(getData.pageId?.mobileImg)} />
            </video> :
            <img className="mobile-video" src={ImageLink(getData.pageId?.mobileImg)} alt='mobile_img' />
          }
        </div>
        {getData.pageId?.contentData?.map((item, id) => {
          return (
            <React.Fragment key={id}>
              <section className="fabrics-area pt-80 pb-80">
                <div className="container">
                  <div className="row align-items-center">

                    <div className="col-lg-8">
                      {item.desktopImg.endsWith('.mp4') ?
                        <video className="mobile-video" width="100%" loop
                          autoPlay
                          muted>
                          <source src={ImageLink(item.desktopImg)} />
                        </video> : <img className="img-radius mb-5" src={ImageLink(item.desktopImg)} alt='Product_img' />}
                    </div>
                    <div className="col-xl-4 col-lg-4">
                      <div>
                        <div className="section-title text-left mb-35">
                          <h2 className="title">{item.title}</h2>
                        </div>
                        <div className="text-left">
                          <p dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="fabrics-area pt-0 pb-80">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12">
                      <div className="row">
                        {item.product.map((product, id) => {
                          return (
                            <div className="col-lg-3" key={id}>
                              <img className="img-radius" src={ImageLink(product.desktopImg)} alt='product_details' />
                              <div className="pro-details">
                                <h5>{product.title}</h5>
                                <p>{product.description}</p>
                              </div>
                            </div>)
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          )
        })}
      </main>
    </>
  )
}

export default Fabrics