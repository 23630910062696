import React, { useEffect, useState } from 'react'
import RoadMap from './RoadMap'
import Section5 from '../components/Section5'
import { useLocation } from 'react-router-dom';
import { getMenuPage } from '../services/getMenuPage';
import { ImageLink } from '../constants';
import PreLoader from '../components/PreLoader';
import { Helmet } from 'react-helmet';

const About = () => {
    const location = useLocation();
    const menuUrl = location.pathname
    const [getData, setGetData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getMenuPage(menuUrl);
            setGetData(response.data);
            setLoading(false);
        };

        fetchData();
    }, [menuUrl]);

    if (loading || getData.length > 0) {
        return <PreLoader />;
    }
    return (
        <>
            <Helmet>
                <title>{getData?.metaTitle}</title>
                <meta name="description" content={getData?.metaDescription} />
            </Helmet>
            <main>
                <section className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">{getData.pageId?.title}</h2>
                                    <h4 dangerouslySetInnerHTML={{ __html: getData.pageId?.content }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {getData.pageId?.contentData.map((item, id) => {
                    return (
                        <React.Fragment key={id}>
                            {id === 0 ?
                                <section className="aboutpage-area pt-80">
                                    <div className="container">
                                        <div className="row align-items-center">

                                            <div className="col-lg-6">
                                                <div className="about-img">
                                                    <img src={ImageLink(item.desktopImg)} alt="about_img" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <div className="section-title mb-20">
                                                        <h2 className="inn-sub-title">{item.title}</h2>
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> : ''}
                        </React.Fragment>
                    );
                })}

                <section className="aboutpage-area-two pt-140 pb-80">
                    <div className="container">
                        <div className="row align-items-center">
                            {getData.pageId?.contentData.map((item, id) => {
                                return (
                                    id !== 0 ?
                                        <div className="col-lg-6" key={id}>
                                            <div>
                                                <div className="section-title mb-20">
                                                    <h2 className="inn-sub-title white-text">{item.title}</h2>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>
                                        </div> : '')
                            })}
                        </div>
                    </div>
                </section>
                <RoadMap />
                <Section5 />
            </main>
        </>
    )
}

export default About
